import React, { Component, createRef } from "react"
import { graphql, StaticQuery } from "gatsby"
import SEO from "../components/seo"
import Layout from "../modules/layout"
import Hero from "../modules/hero"
import RichText from "../components/page-builder/richText"
import { mountMicroappScript, unmountMicroapp } from "../lib/breezy"

import "./careers.scss"

const PF_CAREERS_APP_CONFIG = {
  microapp: "job-board",
  id: "pf-site-careers",
  datapath: "https://ltg.breezy.hr/json",
  department: "PeopleFluent"
}

class CareersTemplate extends Component {
  constructor(props) {
    super(props)
    this.careersContainer = createRef()
  }

  componentDidMount() {
    mountMicroappScript(this.careersContainer.current, {
      ...PF_CAREERS_APP_CONFIG,
    })
  }

  componentWillUnmount() {
    unmountMicroapp(PF_CAREERS_APP_CONFIG.id)
  }

  render(props) {
    const title = `Careers`
    const description = null
    const image = null
    return (
      <Layout>
        <SEO
          title={title}
          description={description}
          image={image}
        />
        <Hero heading="Come work with us" illustration="No Illustration">
          <h2>We're looking for great people to join our growing team</h2>
        </Hero>
        <div
          className="careers-container"
          id={PF_CAREERS_APP_CONFIG.id}
          ref={this.careersContainer}
        />
        <section className="careersPolicy">
          <div className="grid">
            <div className="gridItem gridItemCenter large-9">
              <RichText blocks={this.props.sanityCareers._rawPolicyInfo} />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

const Careers = () => (
  <StaticQuery
    query={graphql`
      query {
        sanityCareers {
          _rawPolicyInfo
        }
      }
    `}
    render = {(data) => <CareersTemplate {...data} />}
  />
)

export default Careers
